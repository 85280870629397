@charset "UTF-8";

@import './variables'; 

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {  
  // font-size: 62.5%;
  display: flex;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: $base-font-family;
  font-size: $base-font-size;
  overflow-x: hidden;
  background-color: white;
}

.sg_footer,
.sg_header {
  flex-shrink: 0;
}
.sg_main {
  flex-grow: 1;  
  @media (min-width: $breakpoint-big-min) {
    // padding-left: 14em;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

h1 {
  font-size: (28/16) * 1em;
  line-height: (28/16) * 1em;
}
h2 {
  font-size: (24/16) * 1em;
  line-height: (26/16) * 1em;
}
h3 {
  font-size: (20/16) * 1em;
  line-height: (30/16) * 1em;
}
h4 {
  font-size: (18/16) * 1em;
  line-height: (22/16) * 1em;
}

a {
  color: $green-1;
}
.c_text-link {
  display: inline-block;
  padding: $space-xs 0;
  transition: background-color 100ms ease-in;
  border: 2px solid transparent;
  &:hover,
  &--hover {
    background: $green-4;
  }
  &:focus,
  &--focus {
    @include focus-border($gray-1);
  }
}

p {
  margin-bottom: $space-l;
  line-height: 1.8em;
  a {
    color: $green-1;
    &:focus {
      @include focus-border($green-1);
    }
    @media (min-width: $breakpoint-med-min) {
      &:hover {
        color: $black-1;
        text-decoration: none;
      }
    }
  }
  &.ingress {
    font-style: italic;
    font-size: (18/16) * 1em;
  }
}
ul,
ol {
  li {
    margin-bottom: $space-m;
  }
}
ol {
  padding-left: 1rem;
}
ul {
  list-style: none;
  & > li {
    // margin-bottom: 1.5rem;
    & > ul {
      padding-left: 1rem;

      & > li {
        margin-bottom: 1rem;
      }
    }
  }
}
ol > li > ul, ol > li > ol, ul > li > ul, ul > li > ol {
  display: block;
  margin-top: 1rem;
}
ul > li,  ol > li > ul > li, ul > li > ul > li{
  &::before {
      content: '\2022';
      color: #4c6e6e;
      width: 1.5rem;
      margin-right: 0.25rem;
      font-weight: bold;
  }
}

.dim2 {
  font-family: $light-font-family;
}
.dim3 {
  font-family: 'Palatino';
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
// Den første fontsize er fra da body hadde 62.5% fontsize
@media (min-width: $breakpoint-med-min) {
  h1 {
    font-size: (34/16) * 1em;
    line-height: (36/16) * 1em;
  }
  h2 {
    font-size: (30/16) * 1em;
    line-height: (24/16) * 1em;
  }
  h3 {
    font-size: (24/16) * 1em;
    line-height: (34/16) * 1em;
  }
  h4 {
    font-size: (22/16) * 1em;
    line-height: (30/16) * 1em;
  }
  p {
    &.ingress {
      font-size: (20/16) * 1em;
      line-height: (28/16) * 1em;
    }
  }
}

.g_main {
  margin-top: $space-m;
}

.g_center {
  margin: 0 auto;
  width: 90%;
  max-width: 1084px;
  @media (min-width: $breakpoint-med-min) {
    width: 80%;
  }
  @media (min-width: $breakpoint-big-min) {
    width: 85%;
  }
}

.c-block {
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }
}