@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_colorsMiljostatus.scss';

.t_article-page {
	.c_article-page-header {
		margin-bottom: 1em;

		/* @media (min-width: $breakpoint-med-min) {
			padding-bottom: 2.5em;
        }
 */
        &__heading {
            color: $black-2;
            font-size: 1.89em;
            line-height: 1.53em;
        }
        &__preamble {
            color: $black-1;
            font-size: 1.112em;
            margin: 1em 0;

            @media (min-width: $breakpoint-med-min) {
                // margin: 2em 0 1.66em 0;
                font-size: 1.12em;
            }
        }
    }
    .c_article-page-main-content {
        @include letter-spacing(20);
        font-size: .89em;
        color: $black-1;
    }
}