@import '../../../styles/_variables.scss';
@import '../../../styles/_colors.scss';
@import '../../../styles/_colorsMiljostatus.scss';
@import '../../../styles/_mixins.scss';

.c_shortcut {
	box-sizing: border-box;
	color: $color--black;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	overflow: hidden;
	background-color: $green-5;
	text-align: left;
	text-decoration: none;
	margin: 0 0 $container-padding 0;
	padding: 1.2em 1em 1em 1em;
	transition: background-color 0.3s ease;
	position: relative;

	@media (min-width: $breakpoint-med-min) {
		margin-bottom: 1em;
		padding: 2.5em;
	}

	@include hover() {
		background-color: $green-5;

		.c_shortcut__title {
			text-decoration: underline;
		}
		&.c_shortcut--block {
			.c_shortcut__icon {
				background-color: white;
			}
		}
	}

	&:focus-within,
	&:focus {
		outline: none;
		background-color: $green-5;

		&:after {
			content: '';
			width: 100%;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $focus-outline-color;
		}

		.c_shortcut__title {
			text-decoration: underline;
		}
	}

	// Content styling ================================
	&__icon {
		display: block;
		width: 100%;
		height: 100%;
		max-width: 45px;
		max-height: 45px;
		margin: 0 auto;

		@media (min-width: $breakpoint-med-min) {
			max-width: 67px;
			max-height: 67px;
			margin: 0 2.6em 0 1em;
		}

		svg {
			width: 45px;
			height: 45px;

			@media (min-width: $breakpoint-med-min) {
				width: 67px;
				height: 67px;
			}
		}
		& + .c_shortcut__text-container {
			.c_shortcut__description {
				&:before {
					content: none;
				}
			}
		}
	}

	&__text-container {
		flex: 1 1 auto;
		margin-right: 0.75em;

		@media (min-width: $breakpoint-med-min) {
			margin-right: 1em;
		}
	}

	&__title {
		display: inline-block;
		font: {
			family: $base-font-family;
			size: 1.12em;
		}

		@media (min-width: $breakpoint-med-min) {
			font-size: 1.445em
		}
	}

	&__description {
		display: block;
		font: {
			family: $light-font-family;
			size: 1.112em
		}
		margin-top: 0.25em;
		line-height: 1.4;

		&:before {
			content: '';
			width: 115px;
			margin: 1em auto;
			z-index: 1;
			height: 1px;
			display: block;
			background-color: lightgray;
		}
	}

	&__arrow-right {
		flex: 0 0 auto;
		height: 0.9em;
		width: 0.6em;
		margin-top: -0.1em;

		@media (max-width: $breakpoint-small-max) {
			height: 0.9em;
			width: auto;
			margin: 0;
		}

		svg {
			display: block;
			height: 0.9em;
			width: 0.6em;

			@media (max-width: $breakpoint-small-max) {
				height: 0.7em;
				width: 0.4em;
				margin-top: -0.1em;
			}
		}
	}

	&__redirect-icon {
		width: 1.167em;
		height: 1.167em;
		margin-top: -0.175em;

		@media (max-width: $breakpoint-small-max) {
			height: 1.111em;
			width: 1.111em;
		}

		svg {
			width: 1.167em;
			height: 1.167em;

			@media (max-width: $breakpoint-small-max) {
				height: 1.111em;
				width: 1.111em;
			}
		}
	}

	// Special styling for shortcut component types audience and warning:
	&--audience, &--warning {
		display: block;
		text-align: center;
		padding: 1em 2em;

		@media (min-width: $breakpoint-med-min) {
			display: flex;
			padding: 2.5em;
			text-align: left;
			align-items: center;
			justify-content: space-between;
		}

		.c_shortcut__icon {
			width: 100%;

			@media (min-width: $breakpoint-med-min) {
				width: auto;
				flex: 1 1 auto;
			}
		}

		.c_shortcut__text-container {
			@media (min-width: $breakpoint-med-min) {
				flex: 1 1 auto;
			}
		}

		.c_shortcut__title {
			display: block;
			margin-top: 0.8em;

			@media (min-width: $breakpoint-med-min) {
				font-size: 1.225em;
				margin-top: 0.2em;
			}
			&__arrow-right {
				margin-top: 1.5em;
			}
		}
		.c_shortcut__description {
			&:before {
				content: none;
			}
		}

		.c_shortcut__arrow-right {
			margin-top: 1.5em;

			@media (min-width: $breakpoint-med-min) {
				margin-top: 0;
				flex: 0 0 auto;
			}
		}
	}
	&--audience {
		border: 1px solid;
	}
	&--block {
		text-align: center;
		background-color: transparent;
		border: 1px solid;
		flex-direction: column;

		.c_shortcut__icon {
			background-color: $green-5;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			max-width: 100px;
			max-height: 100px;

			display: flex;
			justify-content: center;
			align-items: center;

			margin-bottom: 1em;

			svg {
				width: 61px;
				height: 61px;
			}
		}
		.c_shortcut__text-container {
			margin-bottom: 1em;
		}
		.c_shortcut__description {
			margin-top: 1em;
		}
	}
	&--warning {
		background-color: $color--pumpkin-20;

		@include hover() {
			background-color: $color--pumpkin-30;
		}

		&:focus-within,
		&:focus {
			background-color: $color--pumpkin-30;
		}

		.c_shortcut__icon {
			@media (min-width: $breakpoint-med-min) {
				width: 55px;
				height: 55px;
				text-align: center;
			}

			svg {
				@media (min-width: $breakpoint-med-min) {
					max-width: 55px;
					max-height: 55px;
				}
			}
		}

		.c_shortcut__title {
			@media (min-width: $breakpoint-med-min) {
				margin-top: 0;
			}
		}
	}

	&--old-website {
		background-color: #efefef;

		&:focus-within,
		&:focus {
			background-color: darken(#efefef, 5%);
		}

		@include hover() {
			background-color: darken(#efefef, 5%);
		}
	}
}
