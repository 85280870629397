// Miljostatus colors
$color-miljostatus--dark-blue-1: #1E4F71;
$color-miljostatus--dark-blue-1--hover: #19384E;
$color-miljostatus--dark-blue-2: #19384E;
$color-miljostatus--grey-blue-1: #F5F8FB;
$color-miljostatus--grey-blue-2: #ECF2F7;
$color-miljostatus--grey-blue-3: #D6E1E8;
$color-miljostatus--grey-blue-4: #C0D1DD;
$color-miljostatus--focus-orange: #BD6B00;
$color-miljostatus--black: #222222;
$color-miljostatus--light-grey: #CCCCCC;
$color-miljostatus--menu-border-grey: #82888B;

$color-miljostatus--background-white: #fefefe;
$color-miljostatus--pale-grey: #ebf3f3;