@import './_variables.scss';

/* stylelint-disable at-rule-no-unknown */
@mixin removeButtonAppearance() {
	background: none;
	color: inherit;
	border: none;
	cursor: pointer;
//	outline: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	font-smoothing: inherit;
	font-family: $base-font-family !important;
}

@mixin transitionStandard() {
	transition: all 0.3s ease;
}

@mixin hover() {
	@media (min-width: $breakpoint-big-min) {
		&:hover {
			@content;
		}
	}
}

@mixin containerLargeWidthChange() {
	@media (min-width: 1024px) {
		width: 85%;
	}
/*
	@media (min-width: 1220px) {
		width: 75%;
	}

	@media (min-width: 1465px) {
		width: 65%;
	}
	*/
}

@mixin fadeInAnimation() {
	opacity: 0;
	animation: fadeIn 0.3s ease-out 1 forwards;
}

@mixin stickyAnchorMenuAnimIn() {
	animation: animateAnchorsIn 0.4s ease-out forwards;
}

@keyframes animateAnchorsIn {
	from { transform: translateY(-3em); }
	to { transform: translateY(0); }
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@function calc-em($target-px, $context) {
	@return ($target-px / $context) * 1em;
}
  
// and modified to accept a base context variable

@function em($pixels, $context: $base-font-size) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $pixels / $context * 1em;
}

@mixin letter-spacing($spacing) {
	letter-spacing: ($spacing / 1000) * 1em;
  }