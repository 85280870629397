@import '../../../styles/_mixins.scss';
@import '../../../styles/variables';
@import '../../../styles/_colorsMiljostatus.scss';
.c_main-header {
    // display: flex;
    position: relative;
    padding: $space-s 0;
    // align-items: center;
    background-color: $green-5;
    margin-bottom: 3em;
    
    &__content-wrapper {
        padding: 0 $space-s;
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
    }
    h1 {
        position: relative;
        margin-left: $space-m;
        font-size: (14/16) * 1em;
        line-height: inherit;
    }
    &__go-home {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:focus {
            @include focus-border($gray-1);
        }
        margin-right: auto;
        >span {
            position: relative;
            &:after {
                display: block;
                position: absolute;
                top: 0;
                right: -$space-s;
                width: 1px;
                height: 100%;
                background-color: $green-1;
                content: '';
            }
        }
    }
    &__profile {
        display: none;
    }
    .skip-link {
        position: absolute;
        top: $space-s;
        left: $space-s;
        overflow: hidden;
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        background-color: #ebf3f3;
        color: #222;
        opacity: 0;
        &:focus {
            margin: 0;
            padding: $space-xs;
            height: auto;
            width: auto;
            opacity: 1;
            background: #fff;
            overflow: auto;
            z-index: 3;
            border: 2px solid $green-2;
            outline: none;
            transition: all .2s ease;
        }
    }
    .c_header-search {
        margin-top: $space-m;
        &__content {
            .c_input {
                margin-bottom: 0;
            }
        }
    }
    .c_button--log-in-link {
        display: none;
    }
    @media (min-width: $breakpoint-med-min) {
        padding: $space-m 0;
        .skip-link {
            top: $space-xxs;
            left: 0;
        }
        &__content-wrapper {
            // padding: 0 $space-m;
        }
        h1 {
            margin-left: $space-m;
            margin-right: $space-s;
            max-width: 470px;
            font-size: (24/16) * 1em;
        }
        .c_header-search {
            flex-grow: 1;
            margin: 0 $space-s;
        }
        &__profile {
            display: inline-block;
            a {
                display: block;
                svg {
                    display: block;
                    margin: 0 auto;
                    width: 70%;
                }
            }
        }
    }
    @media (min-width: $breakpoint-big-min) {
        &__content-wrapper {
            // padding: 0 $space-xxl;
        }
        .c_button--log-in-link {
            display: inline-block;
        }
    }
   /*  @media (min-width: $breakpoint-mega) {
        z-index: 10;
    } */
}

.c_main-logo {
    display: inline-block;
    width: 44px;
    height: 44px;
    svg {
        width: 100%;
        height: 100%;
    }
    @media (min-width: $breakpoint-med-min) {
        width: 50px;
        height: 50px;
        svg {
            width: 50px;
            height: 50px;
        }
    }
}

.c_nav-toggle-button {
    @include removeButtonAppearance();
  /*   border-left: 1px solid $color--white;
    cursor: pointer;
    font: {
        family: $base-font-family;
        size: 1em;
    }
    outline: none;
    padding: 1.5em 1.85em 1.1em 1.4em;
    position: absolute;
    right: -$container-padding;
    bottom: 0;
    max-height: 4em;
    margin: 0;
    box-sizing: border-box;
    transition: hover 0.3s ease,
    outline 0.1s ease;
    background-color: $green-2;
    border: none;
    color: $color--bright-white;
    padding: 1.1em 1.1em 0.85em 1.1em;
    position: relative;
    right: 0;
    min-height: 2.944em; */

    font: {
        family: $base-font-family;
        size: 1em;
    }
    padding: 0.8em .6em;
    background-color: $green-2;
    color: $color--bright-white;
    height: 44px;

    @media(min-width: $breakpoint-med-min) {
        padding: .8em;
        height: 50px;
    }
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        height: 58px;
    }
    @include hover() {
        background-color: $green-1;
        border-color: $green-1;
        .c_nav-toggle-button__text {
            text-decoration: underline;
        }
    }
    &:focus {
        @include focus-border($green-2);
    }
    .c_nav-toggle-button__text {
        margin-right: 0.833em;
    }
    .c_nav-toggle-button__icon {
        width: 1.111em;
        height: 0.97em;
        margin-bottom: -0.1em;
        &--close-menu {
            width: 0.889em;
        }
    }
}