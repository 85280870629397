@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.c-boxed-content-block {
    background-color: $green-5;
    padding: 2.5em;
    color: $black-1;
    font-size: 16px;

    @include letter-spacing(20);

    &__heading {
        margin-bottom: $space-m;
        color: $black-2;
    }
    @media(min-width: $breakpoint-med-min) {
        padding: 2em 4.4em;
    }
}