@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_utilities.scss';

.c_header-breadcrumbs {
	color: $black-1;
	margin-top: -2em;
    margin-bottom: 1.5em;

	a, link, link:visited {
		color: $green-2;
	}

	ul {

		.c_header-breadcrumbs__item {
			display: block;
			float: left;
			line-height: 1.35;
			font-family: $base-font-family;

			&:last-of-type {
				color: $green-1;
			}

			a {
				@include hover() {
					text-decoration: none;
                    cursor: pointer;
                }
			}

            &-seperator {
                margin-left: 1em;
                margin-right: 1em;
            }
		}
	}
}