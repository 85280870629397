body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_master-nav {
  margin-top: 1px;
  height: 100%;
  overflow: hidden;
  z-index: 2000; }
  @media (min-width: 1200px) {
    .c_master-nav {
      position: fixed;
      top: 0;
      right: 0;
      opacity: 0;
      margin: 0; } }
  .c_master-nav--overflowing .c_master-nav__bottom-gradient:before {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS44OSAxMy41Ij48dGl0bGU+aWNvbl9zb3J0X3RhYmxlPC90aXRsZT48cGF0aCBkPSJNMTEuNjcsN2EuNzQuNzQsMCwwLDAtMS4wNiwwTDYuNjksMTAuOTRWLjc1YS43NS43NSwwLDAsMC0xLjUsMFYxMC45NEwxLjI4LDdBLjc0Ljc0LDAsMCwwLC4yMiw3YS43NS43NSwwLDAsMCwwLDEuMDZsNS4xOSw1LjE5YS44Mi44MiwwLDAsMCwuMjUuMTYuNzEuNzEsMCwwLDAsLjI4LjA2LjcyLjcyLDAsMCwwLC4yOS0uMDYuNjcuNjcsMCwwLDAsLjI0LS4xNmw1LjItNS4xOUEuNzUuNzUsMCwwLDAsMTEuNjcsN1oiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 1em;
    opacity: 0.75; }
    @media (min-width: 1200px) {
      .c_master-nav--overflowing .c_master-nav__bottom-gradient:before {
        width: 1.25em;
        height: 1.25em; } }
  .c_master-nav--open .c_master-nav__bottom-gradient:before {
    -webkit-animation: bounce 4s 0s 1 forwards ease;
            animation: bounce 4s 0s 1 forwards ease; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  80% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.75; }
  100% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
    opacity: 0; } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  80% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.75; }
  100% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
    opacity: 0; } }
  .c_master-nav .bm-overlay {
    background: transparent !important; }
    @media (max-width: 1199px) {
      .c_master-nav .bm-overlay {
        top: 0; } }
  @media (max-width: 1199px) {
    .c_master-nav .bm-menu-wrap {
      top: 0; } }

.c_master-nav__menu {
  background-color: #f5f5f5;
  box-sizing: border-box;
  font-weight: normal;
  padding: 0 20px 20px 20px; }
  @media (min-width: 1200px) {
    .c_master-nav__menu {
      max-width: 550px;
      padding: 0; } }
  .c_master-nav__menu .bm-menu {
    overflow-y: scroll !important;
    -ms-overflow-style: none !important;
    width: 100% !important;
    margin-top: 20px; }
    @media (min-width: 1200px) {
      .c_master-nav__menu .bm-menu {
        max-width: 550px;
        padding: 1.3em 2.778em 0 2.778em !important;
        margin-top: 0; } }
    .c_master-nav__menu .bm-menu::-webkit-scrollbar {
      width: 0 !important; }
  .c_master-nav__menu .c_close-button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    font-smoothing: inherit;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-size: 0.845em;
    float: right;
    padding: 37px 0;
    margin-right: 0.25em;
    margin-top: 0.5em; }
    @media (min-width: breakpoint-big-min) {
      .c_master-nav__menu .c_close-button {
        margin-right: -0.5em;
        margin-top: -0.5em; } }
    .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper {
      float: right; }
      .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper .c_close-button__text {
        display: block;
        margin-top: 0.015em;
        float: left;
        display: none; }
      .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper svg {
        height: 21px;
        width: 21px;
        margin-left: 0.88em; }
    @media (min-width: 1200px) {
      .c_master-nav__menu .c_close-button:hover .c_close-button__text {
        text-decoration: underline; } }
    .c_master-nav__menu .c_close-button--focus-to-show {
      opacity: 0;
      border: #3285CE solid 2px;
      pointer-events: none;
      text-decoration: underline; }
      .c_master-nav__menu .c_close-button--focus-to-show:focus {
        opacity: 1;
        pointer-events: all; }
  .c_master-nav__menu .c_search-field {
    width: 100%;
    margin-bottom: 1em; }
  .c_master-nav__menu ul {
    list-style-type: none;
    padding: 0; }
  .c_master-nav__menu .c_nav-section-list {
    margin: 0; }
    .c_master-nav__menu .c_nav-section-list__level-btns-wrapper {
      width: calc(100% - 7em);
      display: inline-block; }
    .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item {
      padding-bottom: 2em; }
      .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        font-smoothing: inherit;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
        background-color: #B4C6D2;
        border-bottom: 1px solid #1E4F71;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
        font-size: 0.79em;
        margin-top: 5px;
        margin-right: 0.7em;
        margin-bottom: 0.35em;
        padding: 0.8em 0.9em 0.61em 0.9em;
        transition: all 0.3s ease; }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button:hover {
            background-color: #a7bcca; }
            .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button:hover span {
              text-decoration: underline; } }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button {
            margin-top: 0;
            margin-bottom: 0.7em; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button svg {
          height: 6px;
          width: 10px;
          margin-top: 4px;
          padding-right: 10px;
          display: inline-block;
          float: left; }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button span {
          display: inline-block; }
      .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title {
        color: #222222;
        display: block;
        font-size: 1em;
        border-bottom: 1px solid #82888B;
        margin: 0;
        padding: 0.7em 20px;
        text-decoration: none;
        outline: none;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title {
            padding: 0.7em 0;
            font-size: 1.222em; } }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title:hover {
            text-decoration: underline; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__text {
          display: inline-block;
          width: 85%; }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__label {
          width: 15%;
          font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
          font-size: 0.833em;
          float: right;
          text-align: right; }
          @media (min-width: 1200px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__label {
              font-size: 0.7em;
              line-height: 2em; } }
    .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level {
      margin-top: 1em; }
      .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_section-list-item__title {
        margin-top: 1.5em; }
      .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
        font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
        padding: 0.83em 0 0.95em 0; }
        @media (max-width: 767px) {
          .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
            font-size: 0.889em; } }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
            padding: 0.91em 0.455em 0.9em 0.455em; } }
    @media (max-width: 767px) {
      .c_master-nav__menu .c_nav-section-list .c_nav-list {
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .c_master-nav__menu .c_nav-section-list .c_nav-list--two-column {
        -webkit-column-count: 2;
                column-count: 2;
        grid-column-gap: 3em;
        -webkit-column-gap: 3em;
                column-gap: 3em; } }
    .c_master-nav__menu .c_nav-section-list .c_nav-list--sub-level {
      margin: 0; }
    .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item {
      border-bottom: 1px solid #82888B;
      padding: 0 0 0 20px;
      display: inline-block;
      width: 100%;
      box-sizing: border-box; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item:first-of-type {
        border-top: 1px solid #82888B; }
      @media (min-width: 1200px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item {
          padding: 0;
          font-size: 1.222em; } }
      @media (min-width: 1200px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item > div {
          margin-left: -0.455em;
          margin-right: -0.455em; } }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        font-smoothing: inherit;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content {
        width: 100%;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
        font-size: 1em;
        text-align: left;
        padding: 0.98em 0 1.05em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        box-sizing: border-box; }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content {
            padding: 0.8em 0.455em 0.78em 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button:focus span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content:focus span {
          text-decoration: underline; }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content:hover span {
            text-decoration: underline; } }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button--active:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content--active:hover span {
            text-decoration: none; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content span {
          width: 87%;
          display: inline-block;
          flex: 0 1 auto;
          margin-top: 0.2em;
          line-height: 1.2; }
          @media (min-width: 1200px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content span {
              width: 90%;
              line-height: 1; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__icon {
          height: 1.35em;
          width: 1.35em;
          float: left;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.35em;
          margin-left: 0; }
          @media (min-width: 1200px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__icon {
              margin-left: 0;
              margin-right: 0.6em;
              margin-top: 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__arrow-right, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__arrow-right {
          height: 0.7em;
          width: 0.385em;
          float: right;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.3em; }
          @media (min-width: 1200px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__arrow-right, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__arrow-right {
              margin-right: 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__redirect-icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__redirect-icon {
          height: 0.7em;
          width: 0.7em;
          float: right;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.3em; }
          @media (min-width: 1200px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__redirect-icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__redirect-icon {
              margin-right: 0; } }
      @media (min-width: 1200px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button.c_nav-list__item-content:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a.c_nav-list__item-content:hover span {
          text-decoration: underline; } }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item link, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a:visited {
        color: #222222;
        text-decoration: none; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column {
        font-size: 0.889em;
        display: block;
        border-bottom: 1px solid #82888B; }
        @media (min-width: 1200px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column {
            column-break-inside: avoid;
            -webkit-column-break-inside: avoid; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column button .c_nav-list-item__arrow-right {
          height: 0.55em; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--sub-level {
        font-size: 1em;
        border-bottom-color: #82888B;
        padding: 0 0 0 40px; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block {
        padding-left: 0; }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block:first-of-type {
          border-top: 1px solid #82888B;
          padding-top: 0;
          margin-top: 1em; }

@media (max-width: 767px) {
  body.u_master-nav-menu-open {
    overflow: hidden;
    position: fixed;
    top: 0; }
    body.u_master-nav-menu-open .c_master-header {
      overflow: hidden; } }

/* IE9 fix */
@media (min-width: 1200px) {
  body.u_master-nav-menu-open .c_master-nav {
    opacity: 1; } }

@media (max-width: 479px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 768px) {
  .u_hidden--desktop {
    display: none !important; } }

@media (max-width: 767px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Bold", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

ul.u_list-style-none > li, ol.u_list-style-none > li {
  margin-bottom: unset; }
  ul.u_list-style-none > li::before, ol.u_list-style-none > li::before {
    content: none; }

.wrapper {
  box-sizing: border-box;
  max-width: 1101px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px; }

.row {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.row.reverse {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: auto; }

.col-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 25%;
  max-width: 25%; }

.col-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 50%;
  max-width: 50%; }

.col-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 75%;
  max-width: 75%; }

.col-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  flex-basis: 100%;
  max-width: 100%; }

.col-offset-0 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 0; }

.col-offset-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 8.33333%; }

.col-offset-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 16.66667%; }

.col-offset-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 25%; }

.col-offset-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 33.33333%; }

.col-offset-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 41.66667%; }

.col-offset-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 50%; }

.col-offset-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 58.33333%; }

.col-offset-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 66.66667%; }

.col-offset-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 75%; }

.col-offset-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 83.33333%; }

.col-offset-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 91.66667%; }

.col-offset-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 100%; }

.col {
  -moz-flex-grow: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.start {
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left; }

.center {
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right; }

.top {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle {
  -moz-align-items: center;
  align-items: center; }

.bottom {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first {
  order: -1; }

.last {
  order: 1; }

@media only screen and (min-width: 480px) {
  .container {
    width: 767px; }
  .col-sm {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 0; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 100%; }
  .col-sm {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 768px) {
  .container {
    width: 1199px; }
  .col-lg {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 0; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 100%; }
  .col-lg {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_main-header {
  position: relative;
  padding: 1rem 0;
  background-color: #ebf3f3;
  margin-bottom: 3em;
  /*  @media (min-width: $breakpoint-mega) {
        z-index: 10;
    } */ }
  .c_main-header__content-wrapper {
    padding: 0 1rem;
    display: flex;
    position: relative;
    align-items: center;
    width: 100%; }
  .c_main-header h1 {
    position: relative;
    margin-left: 1.5rem;
    font-size: 0.875em;
    line-height: inherit; }
  .c_main-header__go-home {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-right: auto; }
    .c_main-header__go-home:focus {
      outline-style: solid;
      outline-width: 2px;
      outline-color: #707070;
      outline-offset: 1px; }
    .c_main-header__go-home > span {
      position: relative; }
      .c_main-header__go-home > span:after {
        display: block;
        position: absolute;
        top: 0;
        right: -1rem;
        width: 1px;
        height: 100%;
        background-color: #325553;
        content: ''; }
  .c_main-header__profile {
    display: none; }
  .c_main-header .skip-link {
    position: absolute;
    top: 1rem;
    left: 1rem;
    overflow: hidden;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    background-color: #ebf3f3;
    color: #222;
    opacity: 0; }
    .c_main-header .skip-link:focus {
      margin: 0;
      padding: 0.5rem;
      height: auto;
      width: auto;
      opacity: 1;
      background: #fff;
      overflow: auto;
      z-index: 3;
      border: 2px solid #4c6e6e;
      outline: none;
      transition: all .2s ease; }
  .c_main-header .c_header-search {
    margin-top: 1.5rem; }
    .c_main-header .c_header-search__content .c_input {
      margin-bottom: 0; }
  .c_main-header .c_button--log-in-link {
    display: none; }
  @media (min-width: 768px) {
    .c_main-header {
      padding: 1.5rem 0; }
      .c_main-header .skip-link {
        top: 0.25rem;
        left: 0; }
      .c_main-header h1 {
        margin-left: 1.5rem;
        margin-right: 1rem;
        max-width: 470px;
        font-size: 1.5em; }
      .c_main-header .c_header-search {
        flex-grow: 1;
        margin: 0 1rem; }
      .c_main-header__profile {
        display: inline-block; }
        .c_main-header__profile a {
          display: block; }
          .c_main-header__profile a svg {
            display: block;
            margin: 0 auto;
            width: 70%; } }
  @media (min-width: 1200px) {
    .c_main-header .c_button--log-in-link {
      display: inline-block; } }

.c_main-logo {
  display: inline-block;
  width: 44px;
  height: 44px; }
  .c_main-logo svg {
    width: 100%;
    height: 100%; }
  @media (min-width: 768px) {
    .c_main-logo {
      width: 50px;
      height: 50px; }
      .c_main-logo svg {
        width: 50px;
        height: 50px; } }

.c_nav-toggle-button {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  font-smoothing: inherit;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
  /*   border-left: 1px solid $color--white;
    cursor: pointer;
    font: {
        family: $base-font-family;
        size: 1em;
    }
    outline: none;
    padding: 1.5em 1.85em 1.1em 1.4em;
    position: absolute;
    right: -$container-padding;
    bottom: 0;
    max-height: 4em;
    margin: 0;
    box-sizing: border-box;
    transition: hover 0.3s ease,
    outline 0.1s ease;
    background-color: $green-2;
    border: none;
    color: $color--bright-white;
    padding: 1.1em 1.1em 0.85em 1.1em;
    position: relative;
    right: 0;
    min-height: 2.944em; */
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-size: 1em;
  padding: 0.8em .6em;
  background-color: #4c6e6e;
  color: #ffffff;
  height: 44px; }
  @media (min-width: 768px) {
    .c_nav-toggle-button {
      padding: .8em;
      height: 50px; } }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .c_nav-toggle-button {
      /* IE10+ specific styles go here */
      height: 58px; } }
  @media (min-width: 1200px) {
    .c_nav-toggle-button:hover {
      background-color: #325553;
      border-color: #325553; }
      .c_nav-toggle-button:hover .c_nav-toggle-button__text {
        text-decoration: underline; } }
  .c_nav-toggle-button:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: #4c6e6e;
    outline-offset: 1px; }
  .c_nav-toggle-button .c_nav-toggle-button__text {
    margin-right: 0.833em; }
  .c_nav-toggle-button .c_nav-toggle-button__icon {
    width: 1.111em;
    height: 0.97em;
    margin-bottom: -0.1em; }
    .c_nav-toggle-button .c_nav-toggle-button__icon--close-menu {
      width: 0.889em; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_footer {
  margin: 3rem auto 0 auto;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  background-color: #fff;
  /* @media (min-width: $breakpoint-mega-min) {
    z-index: 10;
  } */ }
  .c_footer a:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: #325553;
    outline-offset: 1px; }
  .c_footer__content-wrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: 100%; }
  .c_footer .inner-wrapper {
    margin-bottom: 1.5rem; }
    .c_footer .inner-wrapper .c_footer__icon {
      max-width: 82px; }
    .c_footer .inner-wrapper--links ul {
      list-style: none; }
      .c_footer .inner-wrapper--links ul li {
        border-top: 1px solid #e0e0e0;
        margin-left: -10px;
        margin-right: -10px;
        padding: 1.5rem 10px; }
        .c_footer .inner-wrapper--links ul li button {
          background: none;
          color: inherit;
          border: none;
          cursor: pointer;
          -webkit-appearance: none;
          appearance: none;
          -webkit-font-smoothing: inherit;
          -moz-osx-font-smoothing: inherit;
          font-smoothing: inherit;
          font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
          font-size: 1em;
          text-decoration: underline;
          color: #325553; }
    .c_footer .inner-wrapper address {
      font-style: normal; }
    .c_footer .inner-wrapper--address div {
      display: block;
      line-height: 1.625em; }
  @media (min-width: 768px) {
    .c_footer__content-wrapper .inner-wrapper--links {
      margin-left: auto; }
      .c_footer__content-wrapper .inner-wrapper--links ul li {
        padding-top: unset;
        padding-bottom: unset;
        border-top: none;
        width: auto; } }
  @media (min-width: 1200px) {
    .c_footer__content-wrapper {
      /* padding-left: $space-xl;
      padding-right: $space-xl; */ }
      .c_footer__content-wrapper .inner-wrapper--links ul {
        display: flex;
        justify-content: flex-end; }
        .c_footer__content-wrapper .inner-wrapper--links ul li {
          padding-right: 1rem;
          margin-right: 1rem;
          margin-bottom: 0;
          border-right: 1px solid #e0e0e0; }
          .c_footer__content-wrapper .inner-wrapper--links ul li:last-of-type {
            border-right: none;
            margin-right: 0; } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_shortcut {
  box-sizing: border-box;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  background-color: #ebf3f3;
  text-align: left;
  text-decoration: none;
  margin: 0 0 20px 0;
  padding: 1.2em 1em 1em 1em;
  transition: background-color 0.3s ease;
  position: relative; }
  @media (min-width: 768px) {
    .c_shortcut {
      margin-bottom: 1em;
      padding: 2.5em; } }
  @media (min-width: 1200px) {
    .c_shortcut:hover {
      background-color: #ebf3f3; }
      .c_shortcut:hover .c_shortcut__title {
        text-decoration: underline; }
      .c_shortcut:hover.c_shortcut--block .c_shortcut__icon {
        background-color: white; } }
  .c_shortcut:focus-within, .c_shortcut:focus {
    outline: none;
    background-color: #ebf3f3; }
    .c_shortcut:focus-within:after, .c_shortcut:focus:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #00615C; }
    .c_shortcut:focus-within .c_shortcut__title, .c_shortcut:focus .c_shortcut__title {
      text-decoration: underline; }
  .c_shortcut__icon {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 45px;
    max-height: 45px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .c_shortcut__icon {
        max-width: 67px;
        max-height: 67px;
        margin: 0 2.6em 0 1em; } }
    .c_shortcut__icon svg {
      width: 45px;
      height: 45px; }
      @media (min-width: 768px) {
        .c_shortcut__icon svg {
          width: 67px;
          height: 67px; } }
    .c_shortcut__icon + .c_shortcut__text-container .c_shortcut__description:before {
      content: none; }
  .c_shortcut__text-container {
    flex: 1 1 auto;
    margin-right: 0.75em; }
    @media (min-width: 768px) {
      .c_shortcut__text-container {
        margin-right: 1em; } }
  .c_shortcut__title {
    display: inline-block;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-size: 1.12em; }
    @media (min-width: 768px) {
      .c_shortcut__title {
        font-size: 1.445em; } }
  .c_shortcut__description {
    display: block;
    font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-size: 1.112em;
    margin-top: 0.25em;
    line-height: 1.4; }
    .c_shortcut__description:before {
      content: '';
      width: 115px;
      margin: 1em auto;
      z-index: 1;
      height: 1px;
      display: block;
      background-color: lightgray; }
  .c_shortcut__arrow-right {
    flex: 0 0 auto;
    height: 0.9em;
    width: 0.6em;
    margin-top: -0.1em; }
    @media (max-width: 767px) {
      .c_shortcut__arrow-right {
        height: 0.9em;
        width: auto;
        margin: 0; } }
    .c_shortcut__arrow-right svg {
      display: block;
      height: 0.9em;
      width: 0.6em; }
      @media (max-width: 767px) {
        .c_shortcut__arrow-right svg {
          height: 0.7em;
          width: 0.4em;
          margin-top: -0.1em; } }
  .c_shortcut__redirect-icon {
    width: 1.167em;
    height: 1.167em;
    margin-top: -0.175em; }
    @media (max-width: 767px) {
      .c_shortcut__redirect-icon {
        height: 1.111em;
        width: 1.111em; } }
    .c_shortcut__redirect-icon svg {
      width: 1.167em;
      height: 1.167em; }
      @media (max-width: 767px) {
        .c_shortcut__redirect-icon svg {
          height: 1.111em;
          width: 1.111em; } }
  .c_shortcut--audience, .c_shortcut--warning {
    display: block;
    text-align: center;
    padding: 1em 2em; }
    @media (min-width: 768px) {
      .c_shortcut--audience, .c_shortcut--warning {
        display: flex;
        padding: 2.5em;
        text-align: left;
        align-items: center;
        justify-content: space-between; } }
    .c_shortcut--audience .c_shortcut__icon, .c_shortcut--warning .c_shortcut__icon {
      width: 100%; }
      @media (min-width: 768px) {
        .c_shortcut--audience .c_shortcut__icon, .c_shortcut--warning .c_shortcut__icon {
          width: auto;
          flex: 1 1 auto; } }
    @media (min-width: 768px) {
      .c_shortcut--audience .c_shortcut__text-container, .c_shortcut--warning .c_shortcut__text-container {
        flex: 1 1 auto; } }
    .c_shortcut--audience .c_shortcut__title, .c_shortcut--warning .c_shortcut__title {
      display: block;
      margin-top: 0.8em; }
      @media (min-width: 768px) {
        .c_shortcut--audience .c_shortcut__title, .c_shortcut--warning .c_shortcut__title {
          font-size: 1.225em;
          margin-top: 0.2em; } }
      .c_shortcut--audience .c_shortcut__title__arrow-right, .c_shortcut--warning .c_shortcut__title__arrow-right {
        margin-top: 1.5em; }
    .c_shortcut--audience .c_shortcut__description:before, .c_shortcut--warning .c_shortcut__description:before {
      content: none; }
    .c_shortcut--audience .c_shortcut__arrow-right, .c_shortcut--warning .c_shortcut__arrow-right {
      margin-top: 1.5em; }
      @media (min-width: 768px) {
        .c_shortcut--audience .c_shortcut__arrow-right, .c_shortcut--warning .c_shortcut__arrow-right {
          margin-top: 0;
          flex: 0 0 auto; } }
  .c_shortcut--audience {
    border: 1px solid; }
  .c_shortcut--block {
    text-align: center;
    background-color: transparent;
    border: 1px solid;
    flex-direction: column; }
    .c_shortcut--block .c_shortcut__icon {
      background-color: #ebf3f3;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      max-width: 100px;
      max-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em; }
      .c_shortcut--block .c_shortcut__icon svg {
        width: 61px;
        height: 61px; }
    .c_shortcut--block .c_shortcut__text-container {
      margin-bottom: 1em; }
    .c_shortcut--block .c_shortcut__description {
      margin-top: 1em; }
  .c_shortcut--warning {
    background-color: rgba(239, 125, 0, 0.2); }
    @media (min-width: 1200px) {
      .c_shortcut--warning:hover {
        background-color: rgba(239, 125, 0, 0.3); } }
    .c_shortcut--warning:focus-within, .c_shortcut--warning:focus {
      background-color: rgba(239, 125, 0, 0.3); }
    @media (min-width: 768px) {
      .c_shortcut--warning .c_shortcut__icon {
        width: 55px;
        height: 55px;
        text-align: center; } }
    @media (min-width: 768px) {
      .c_shortcut--warning .c_shortcut__icon svg {
        max-width: 55px;
        max-height: 55px; } }
    @media (min-width: 768px) {
      .c_shortcut--warning .c_shortcut__title {
        margin-top: 0; } }
  .c_shortcut--old-website {
    background-color: #efefef; }
    .c_shortcut--old-website:focus-within, .c_shortcut--old-website:focus {
      background-color: #e2e2e2; }
    @media (min-width: 1200px) {
      .c_shortcut--old-website:hover {
        background-color: #e2e2e2; } }

.c_subject-tile-list {
  list-style: none; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.t_article-page .c_article-page-header {
  margin-bottom: 1em;
  /* @media (min-width: $breakpoint-med-min) {
			padding-bottom: 2.5em;
        }
 */ }
  .t_article-page .c_article-page-header__heading {
    color: #222222;
    font-size: 1.89em;
    line-height: 1.53em; }
  .t_article-page .c_article-page-header__preamble {
    color: #3e3e3e;
    font-size: 1.112em;
    margin: 1em 0; }
    @media (min-width: 768px) {
      .t_article-page .c_article-page-header__preamble {
        font-size: 1.12em; } }

.t_article-page .c_article-page-main-content {
  letter-spacing: 0.02em;
  font-size: .89em;
  color: #3e3e3e; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c-boxed-content-block {
  background-color: #ebf3f3;
  padding: 2.5em;
  color: #3e3e3e;
  font-size: 16px;
  letter-spacing: 0.02em; }
  .c-boxed-content-block__heading {
    margin-bottom: 1.5rem;
    color: #222222; }
  @media (min-width: 768px) {
    .c-boxed-content-block {
      padding: 2em 4.4em; } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media (max-width: 479px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 768px) {
  .u_hidden--desktop {
    display: none !important; } }

@media (max-width: 767px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Bold", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

ul.u_list-style-none > li, ol.u_list-style-none > li {
  margin-bottom: unset; }
  ul.u_list-style-none > li::before, ol.u_list-style-none > li::before {
    content: none; }

.c_sharepoint_explorer_container h2 {
  font-size: 1.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.8em;
  border-bottom: 2px solid #ccc; }

.c_sharepoint_explorer_container .c_sharepoint_explorer_sub-heading {
  font-size: 1.2em;
  color: #004b46;
  text-decoration: underline;
  margin-bottom: 1em; }

.c_sharepoint_explorer_container .c_sharepoint_explorer_item {
  margin-bottom: 1em;
  display: flex;
  overflow-wrap: break-word; }
  @media (min-width: 1200px) {
    .c_sharepoint_explorer_container .c_sharepoint_explorer_item:hover {
      color: #004b46;
      text-decoration: underline; } }

.c_sharepoint_explorer_container .c_sharepoint_explorer_item-title {
  display: inline-block;
  max-width: 85%; }
  @media (min-width: 769px) {
    .c_sharepoint_explorer_container .c_sharepoint_explorer_item-title {
      max-width: 100%; } }

.c_sharepoint_explorer_container .c_sharepoint_explorer_button__link {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  font-smoothing: inherit;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
  font-size: 1.2em;
  color: #004b46;
  padding: 0;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease; }
  .c_sharepoint_explorer_container .c_sharepoint_explorer_button__link--back {
    margin-bottom: 2em;
    font-size: 1em; }
    @media (min-width: 1200px) {
      .c_sharepoint_explorer_container .c_sharepoint_explorer_button__link--back:hover {
        color: #004b46;
        text-decoration: underline; } }
  .c_sharepoint_explorer_container .c_sharepoint_explorer_button__link--back-icon {
    width: 0.35em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-right: 0.7em; }

.c_sharepoint_explorer_container .c_sharepoint_explorer_button-link--download {
  display: flex;
  text-decoration: none;
  font-size: 1.2em; }

.c_sharepoint_explorer_container .c_sharepoint_explorer_download_icon {
  width: 0.7em;
  margin-right: 1em;
  display: inline-block;
  padding-left: 5px; }

.c_sharepoint_explorer_container .c_sharepoint_explorer_folder_icon--open {
  height: 0.95em;
  width: 1.25em;
  margin-right: 0.7em;
  display: inline-block; }

.c_loading-spinner {
  margin: 4em auto; }
  .c_loading-spinner svg {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    -webkit-animation: spinAnimation 1s linear infinite;
            animation: spinAnimation 1s linear infinite; }

@-webkit-keyframes spinAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  display: flex; }

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-size: 1.125em;
  overflow-x: hidden;
  background-color: white; }

.sg_footer,
.sg_header {
  flex-shrink: 0; }

.sg_main {
  flex-grow: 1; }

h1,
h2,
h3,
h4 {
  font-weight: normal; }

h1 {
  font-size: 1.75em;
  line-height: 1.75em; }

h2 {
  font-size: 1.5em;
  line-height: 1.625em; }

h3 {
  font-size: 1.25em;
  line-height: 1.875em; }

h4 {
  font-size: 1.125em;
  line-height: 1.375em; }

a {
  color: #325553; }

.c_text-link {
  display: inline-block;
  padding: 0.5rem 0;
  transition: background-color 100ms ease-in;
  border: 2px solid transparent; }
  .c_text-link:hover, .c_text-link--hover {
    background: #d7e6e6; }
  .c_text-link:focus, .c_text-link--focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: #707070;
    outline-offset: 1px; }

p {
  margin-bottom: 2rem;
  line-height: 1.8em; }
  p a {
    color: #325553; }
    p a:focus {
      outline-style: solid;
      outline-width: 2px;
      outline-color: #325553;
      outline-offset: 1px; }
    @media (min-width: 768px) {
      p a:hover {
        color: #3e3e3e;
        text-decoration: none; } }
  p.ingress {
    font-style: italic;
    font-size: 1.125em; }

ul li,
ol li {
  margin-bottom: 1.5rem; }

ol {
  padding-left: 1rem; }

ul {
  list-style: none; }
  ul > li > ul {
    padding-left: 1rem; }
    ul > li > ul > li {
      margin-bottom: 1rem; }

ol > li > ul, ol > li > ol, ul > li > ul, ul > li > ol {
  display: block;
  margin-top: 1rem; }

ul > li::before, ol > li > ul > li::before, ul > li > ul > li::before {
  content: '\2022';
  color: #4c6e6e;
  width: 1.5rem;
  margin-right: 0.25rem;
  font-weight: bold; }

.dim2 {
  font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.dim3 {
  font-family: 'Palatino'; }

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

@media (min-width: 768px) {
  h1 {
    font-size: 2.125em;
    line-height: 2.25em; }
  h2 {
    font-size: 1.875em;
    line-height: 1.5em; }
  h3 {
    font-size: 1.5em;
    line-height: 2.125em; }
  h4 {
    font-size: 1.375em;
    line-height: 1.875em; }
  p.ingress {
    font-size: 1.25em;
    line-height: 1.75em; } }

.g_main {
  margin-top: 1.5rem; }

.g_center {
  margin: 0 auto;
  width: 90%;
  max-width: 1084px; }
  @media (min-width: 768px) {
    .g_center {
      width: 80%; } }
  @media (min-width: 1200px) {
    .g_center {
      width: 85%; } }

.c-block {
  margin-bottom: 2em; }
  .c-block:last-of-type {
    margin-bottom: 0; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media (max-width: 479px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 768px) {
  .u_hidden--desktop {
    display: none !important; } }

@media (max-width: 767px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Bold", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

ul.u_list-style-none > li, ol.u_list-style-none > li {
  margin-bottom: unset; }
  ul.u_list-style-none > li::before, ol.u_list-style-none > li::before {
    content: none; }

.c_header-breadcrumbs {
  color: #3e3e3e;
  margin-top: -2em;
  margin-bottom: 1.5em; }
  .c_header-breadcrumbs a, .c_header-breadcrumbs link, .c_header-breadcrumbs link:visited {
    color: #4c6e6e; }
  .c_header-breadcrumbs ul .c_header-breadcrumbs__item {
    display: block;
    float: left;
    line-height: 1.35;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }
    .c_header-breadcrumbs ul .c_header-breadcrumbs__item:last-of-type {
      color: #325553; }
    @media (min-width: 1200px) {
      .c_header-breadcrumbs ul .c_header-breadcrumbs__item a:hover {
        text-decoration: none;
        cursor: pointer; } }
    .c_header-breadcrumbs ul .c_header-breadcrumbs__item-seperator {
      margin-left: 1em;
      margin-right: 1em; }

