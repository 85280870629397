@import '../../../styles/variables';
@import '../../../styles/_mixins.scss';

.c_footer {
  margin: 3rem auto 0 auto;
  border-top: 1px solid $gray-3;
  width: 100%;
  background-color: #fff;
  a {
    &:focus {
      @include focus-border($green-1);
    }
  }
  &__content-wrapper {
    padding-top: $space-l;
    padding-bottom: $space-s;
    width: 100%;
  }
  .inner-wrapper {
    margin-bottom: $space-m;

    .c_footer__icon {
      max-width: 82px;
    }
    &--links {
      ul {
        list-style: none;
        li {
          border-top: 1px solid $gray-3;
          margin-left: -10px;
          margin-right: -10px;
          padding: $space-m 10px;
  
          button {
            @include removeButtonAppearance();
            font-size: 1em;
            text-decoration: underline;
            color: $green-1;
          }
        }
    }
    }
	address {
		font-style: normal;
	}
    &--address {
      div {
        display: block;
        line-height: (26/16) * 1em;
      }
    }
  }
  @media (min-width: $breakpoint-med-min) {
    &__content-wrapper {
      .inner-wrapper {
        &--links {
          margin-left: auto;

          ul {
            li {
              padding-top: unset;
              padding-bottom: unset;
              border-top: none;
              width: auto;
            }
          }
        }
      }
    }
  }
  @media (min-width: $breakpoint-big-min) {
    &__content-wrapper {
      /* padding-left: $space-xl;
      padding-right: $space-xl; */
      .inner-wrapper {
        &--links {
          ul {
            display: flex;
            justify-content: flex-end;
            li {
              padding-right: $space-s;
              margin-right: $space-s;
              margin-bottom: 0;
              border-right: 1px solid $gray-3;
              &:last-of-type {
                border-right: none;
                margin-right: 0;
              } 
            }
          }
        }
      }
    }
  }
  /* @media (min-width: $breakpoint-mega-min) {
    z-index: 10;
  } */
}