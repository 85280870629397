@import '_colors.scss';

// Breakpoints
$breakpoint-xs-max: 479px;

$breakpoint-small-min: 480px;
$breakpoint-small-max: 767px;

$breakpoint-med-min: 768px;
$breakpoint-med-max: 1199px;

$breakpoint-big-min: 1200px;
$breakpoint-big-max: 1400px;


// Spacing
$space-xxs: 0.25rem; // 4px
$space-xs: 0.5rem; // 8px;
$space-s: 1rem; // 16px;
$space-m: 1.5rem; // 24px;
$space-l: 2rem; // 32px;
$space-xl: 2.5rem; // 40px;
$space-xxl: 3rem; // 48px;
$space-xxxl: 3.5rem; // 48px;

@mixin focus-border($color1) {
  // transition: outline-offset 100ms ease-in;
  // background-image: linear-gradient(to right, $color1 50%, transparent 50%), linear-gradient(to right, $color1 50%, transparent 50%), linear-gradient(to bottom, $color1 50%, transparent 50%), linear-gradient(to bottom, $color1 50%, transparent 50%);
  // background-position: left top, left bottom, left top, right top;
  // background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  // background-size: 8px 2px, 8px 2px, 2px 8px, 2px 8px;
  // outline: none;
  outline-style: solid;
  outline-width: 2px;
  outline-color: $color1;
  outline-offset: 1px;
  // border: 2px solid #fff;
}

@mixin gradient($color1, $color2) {
  background: linear-gradient(to bottom, $color1 0%, $color2 100%);
}

@mixin box-shadow() {
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
}

$large-screen-min: 769px;

// Font related
$base-font-size: 1.125em;
$base-font-family: 'PalatinoLTStd-Roman', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$base-italic-font-family: 'PalatinoLTStd-Italic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$light-font-family: 'PalatinoLTStd-Light', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$light-italic-font-family: 'PalatinoLTStd-LightItalic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$medium-font-family: 'PalatinoLTStd-Medium', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$bold-font-family: 'PalatinoLTStd-Bold', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;

// Margins & paddings
$container-padding: 20px;

// Forus settings
$focus-outline-width: 2px;
$focus-outline-offset: 3px;
$focus-outline-color: $color--petrol;

:export {
	fontFamily: $base-font-family;
}