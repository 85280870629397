@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';
@import '../../../styles/_utilities.scss';
@import '../../../styles/_colors.scss';

.c_sharepoint_explorer_container {
    h2 {
        font-size: 1.5em;
        padding-bottom: 0.5em;
        margin-bottom: 0.8em;
        border-bottom: 2px solid #ccc;
    }

    .c_sharepoint_explorer_sub-heading {
        font-size: 1.2em;
        color: $color--dark-blue-green;
        text-decoration: underline;
        margin-bottom: 1em;
    }

    .c_sharepoint_explorer_item {
        margin-bottom: 1em;
        display: flex;
        overflow-wrap: break-word;

        @include hover() {
            color: $color--dark-blue-green;
            text-decoration: underline;
        }
    }

    .c_sharepoint_explorer_item-title {
        display: inline-block;
        max-width: 85%;

        @media (min-width: $large-screen-min) {
            max-width: 100%;
        }
    }

    .c_sharepoint_explorer_button__link {
        @include removeButtonAppearance();
        font-size: 1.2em;
        color: $color--dark-blue-green;
        padding: 0;
        width: 100%;
        text-align: left;
        @include transitionStandard();

        &--back {
            margin-bottom: 2em;
            font-size: 1em;

            @include hover() {
                color: $color--dark-blue-green;
                text-decoration: underline;
            }
        }

        &--back-icon {
            width: 0.35em;
            transform: rotate(180deg);
            margin-right: 0.7em;
        }
    }

    .c_sharepoint_explorer_button-link--download {
        display: flex;
        text-decoration: none;
        font-size: 1.2em;
    }

    .c_sharepoint_explorer_download_icon {
        width: 0.7em;
        margin-right: 1em;
        display: inline-block;
        padding-left: 5px;
    }

    .c_sharepoint_explorer_folder_icon--open {
        height: 0.95em;
        width: 1.25em;
        margin-right: 0.7em;
        display: inline-block;
    }
}
